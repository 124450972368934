import React from "react";
import { Helmet } from 'react-helmet';
import Airtour1 from "../../assets/AirTour/Airtour1.webp";
import Airtour2 from "../../assets/AirTour/Airtour2.png";
import Airtour3 from "../../assets/AirTour/Airtour3.png";
import Airtour4 from "../../assets/AirTour/Airtour4.png";
import Airtour5 from "../../assets/AirTour/Airtour5.png";
import Airtour6 from "../../assets/AirTour/Airtour6.png";

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    box: {
        boxSizing: 'border-box',
        flex: '0 1 calc(33.33% - 10px)',
        marginBottom: '15px',
    },
    boxResponsive: {
        '@media (max-width: 800px)': {
            flex: '0 1 calc(50% - 10px)',
        },
        '@media (max-width: 500px)': {
            flex: '0 1 100%',
        },
    },
};

const AirTours = () => {
    const articles = [
        {
            title: "Sunset Celebration",
            description: "Embark on a 30-minute scenic flight with a champagne toast, watching the sunset over Key West from an unparalleled aerial view. Suitable for up to 3 guests with rates starting at $275 per person.",
            img: Airtour1,
            url: "https://fareharbor.com/embeds/book/fly-keywest/items/249381/?asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&flow=no&branding=yes&bookable-only=yes&ref=boozefreeinkeywest&back=BACKLINK",
        },
        {
            title: "Key West Eco Tour",
            description: "Explore Key Wests vibrant marine life on a 20-minute helicopter eco tour. Spot sea life and sunken treasures from above, with rates starting at $175. Suitable for all ages.",
            img: Airtour2,
            url: "https://fareharbor.com/embeds/book/fly-keywest/items/249367/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
        },
        {
            title: "The Marquesas Explorer",
            description: "Relax on a 45-minute flight over the Marquesas Keys and backcountry waters, searching for marine wildlife. Enjoy tranquility and stunning views with rates starting at $369.",
            img: Airtour3,
            url: "https://fareharbor.com/embeds/book/fly-keywest/items/345324/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
        },
        {
            title: "Key West Island Tour",
            description: "Take off on a 10-minute tour to see Key Wests historical landmarks from the skies. Perfect for a quick adventure with rates starting at $99 for all ages.",
            img: Airtour4,
            url: "https://fareharbor.com/embeds/book/fly-keywest/items/249359/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
        },
        {
            title: "The Key's Grand Tour",
            description: "Experience a comprehensive 60-minute aerial tour of the Lower Florida Keys, including the Barrier Reef and Backcountry, with a chance to spot diverse marine life. Rates start at $465.",
            img: Airtour5,
            url: "https://fareharbor.com/embeds/book/fly-keywest/items/249375/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
        },
        {
            title: "The Ultimate Island Experience - 30 minutes",
            description: "Discover all Key West has to offer on this 30-minute tour of the city landmarks and backcountry waters. Encounter marine life and historic sites, with rates starting at $249.",
            img: Airtour6,
            url: "https://fareharbor.com/embeds/book/fly-keywest/items/249373/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
        },
    ];
    

    return (
        <div className="container my-24 px-6 mx-auto">
            <Helmet>
                <title>KeySaver - Explore Key West from Above with Fly Key West Air Tours</title>
                <meta name="description" content="Join Fly Key West for breathtaking air tours in Key West. Choose from sunset celebrations, eco tours, island tours, and more for a unique aerial perspective of the Florida Keys." />
                <meta name="keywords" content="Key West Air Tours, Fly Key West, Sunset Air Tour, Eco Helicopter Tour, Aerial Island Tour, Florida Keys Scenic Flights"/>
                <meta property="og:title" content="KeySaver - Key West Air Tours by Fly Key West"/>
                <meta property="og:description" content="Experience the beauty of Key West from the skies with Fly Key West. Offering a range of air tours including sunset, eco, and island tours for an unforgettable aerial adventure."/>
                <meta property="og:url" content="https://keysaver.app/air-tours"/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content="URL_to_an_image_representing_air_tours_page"/>
                <link rel="canonical" href="https://keysaver.app/air-tours"/>
            </Helmet>

            <section className="mb-32 text-gray-800">
            <h1 className="text-3xl font-bold mb-12 text-center">
                Book Experiences - Air Tours
            </h1>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
                {articles.map((article, index) => {
                    return (
                        <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
                            <a href={article.url} target="_blank" rel="noopener noreferrer">
                                <img
                                    src={article.img}
                                    className="w-full h-64 object-cover rounded-t-lg"
                                    alt={article.title}
                                />
                                <div className="text-black p-6">
                                    <h5 className="font-bold text-2xl mb-3 font-Poppins">
                                        {article.title}
                                    </h5>
                                    <p className="mb-3">
                                        <small>
                                            {article.description}
                                        </small>
                                    </p>
                                    <a
                                        target="_blank"
                                        href={article.url}
                                        className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                                    >
                                        Book Now
                                    </a>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </div>
        </section>
    </div>
    );
};

export default AirTours;
