import {useAuth} from './AuthContext'
import {Navigate} from 'react-router-dom'

export default function ProtectedRoute({ children }) {
  const { user, loading } = useAuth();

  // If still loading, return null or a loading spinner
  if (loading) {
    return null; // or return <LoadingSpinner />;
  }

  // If not loading and no user, redirect to login
  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
}
