import React from "react";
import { Helmet } from 'react-helmet';
import Haunt1 from "../../assets/HauntedTour/Haunt1.png";
import Haunt2 from "../../assets/HauntedTour/Haunt2.png";
import Haunt3 from "../../assets/HauntedTour/Haunt3.png";
import Haunt4 from "../../assets/HauntedTour/Haunt4.png";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const HauntedTours = () => {
  const articles = [
    {
      title: "Ghost Hunt & Robert the Doll Experience",
      description:
        "Engage in a ghost hunt at Fort East Martello Museum with the infamous Robert the Doll. General Admission is $45 for a 60+ minute tour, or opt for the VIP experience at $85 for a thrilling 120+ minute exploration with a spirit box and flashlight in the dark.",
      img: Haunt1,
      url: "https://fareharbor.com/embeds/book/robertthedollexperience/items/256633/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "The Haunted History Ghost Hunt",
      description:
        "Dive into Key West's haunted past on a 90+ minute tour for $28, or book a private ghost hunt for up to 10 participants at $850. Join expert guides and hunt using state-of-the-art equipment to possibly encounter the supernatural.",
      img: Haunt2,
      url: "https://fareharbor.com/embeds/book/keywestghosthunt/items/11364/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&sheet=219476&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Key West Ghost & Mysteries Tour",
      description:
        "Experience the eerie side of Key West on a 1.5-hour tour with tales of local haunts. Adults pay $23, children $15, and military discounts are offered. Explore haunted locations and engage with the spirits that roam our island.",
      img: Haunt3,
      url: "https://fareharbor.com/embeds/book/keywestghostandmysteriestour/items/26277/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Bone Island Haunted Pub Crawl",
      description:
        "Mix with the spirits of Key West on this 2-hour haunted pub crawl available for $24.95. Rated among the Top 5 most haunted places, Bone Island awaits with stories to chill your spine. Participants must be 21+.",
      img: Haunt4,
      url: "https://fareharbor.com/embeds/book/keywestpromotions/items/203673/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];  

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>KeySaver - Explore Key West's Haunted Tours and Experiences</title>
        <meta name="description" content="Join KeySaver for a thrilling adventure into the haunted side of Key West. From ghost hunts to haunted pub crawls, uncover the island's eerie history and mysteries." />
        <meta name="keywords" content="Key West Haunted Tours, Ghost Hunts, Haunted Pub Crawl, Paranormal Experiences, Robert the Doll, Key West Ghost Stories, Eerie Key West"/>
        <meta property="og:title" content="KeySaver - Key West Haunted Adventures"/>
        <meta property="og:description" content="Discover the ghostly tales and spirits of Key West with KeySaver's haunted tours. Experience the island's paranormal side for an unforgettable adventure."/>
        <meta property="og:url" content="https://keysaver.app/haunted-tours"/>
        <meta property="og:image" content="https://keysaver.app/static/media/haunted1.8abb5da8aae42dc7f9ea.png"/>
        <link rel="canonical" href="https://keysaver.app/haunted-tours"/>
      </Helmet>
      
      <section className="mb-32 text-gray-800">
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences - Haunted Tours
        </h1>
        <div className="grid lg:grid-cols-3 gap-16"> {/* Increased gap for better spacing */}
          {articles.map((article, index) => (
            <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center"> {/* Added text-center */}
              <a href={article.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={article.img}
                  className="w-full h-64 object-cover rounded-t-lg" // Fixed height for uniformity
                  alt={`Haunted Tour: ${article.title} - ${article.description}`}
                />
                <div className="text-black p-6"> {/* Moved text inside the a tag but outside the image */}
                  <h5 className="font-bold text-2xl mb-3 font-Poppins">
                    {article.title}
                  </h5>
                  <p className="mb-3">
                    <small>
                      {article.description}
                    </small>
                  </p>
                  <a
                    target="_blank"
                    href={article.url}
                    className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                </div>
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HauntedTours;

