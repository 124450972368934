import React from "react";
import { Helmet } from 'react-helmet';
import Charter1 from "../../assets/CharterTour/Charter1.png";
import Charter2 from "../../assets/CharterTour/Charter2.png";
import Charter3 from "../../assets/CharterTour/Charter3.png";
import Charter4 from "../../assets/CharterTour/Charter4.png";
import Charter5 from "../../assets/CharterTour/Charter5.png";
import Charter6 from "../../assets/CharterTour/Charter6.png";
import SaltySoul from "../../assets/CharterTour/SaltySoul.jpg";
import SaltySoul_half from "../../assets/CharterTour/SaltySoul_half.jpg";
import SaltySoulSunset from "../../assets/CharterTour/SaltySoulSunset.jpg";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const PrivateCharter = () => {
  const articles = [
    {
      title: "Barefoot Billy's Private Charters in Key West",
      description: "Private charters from Garrison Bight Marina with options for 4, 6, or 8 hours. Enjoy plush seating and shaded areas while exploring secluded sandbars and wildlife. Prices start at $699 for up to 6 passengers.",
      img: Charter1,
      url: "https://fareharbor.com/embeds/book/barefootbillyskw/items/271884/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Salty Soul Charters - Full Day",
      description: "A 6-hour private fishing trip thats perfect for all ages. Enjoy activities customized to your preferences. Prices: $1,025 for private charters with fishing (up to six people).",
      img: SaltySoul,
      url: "https://fareharbor.com/embeds/book/saltysoulcharters/items/324531/calendar/2023/11/?flow=1000849&asn=boozefreeinkeywest&full-items=yes&ref=ASN"
    },
    {
      title: "Salty Soul Charters - Half Day",
      description: "Enjoy the magic of Key West with a 4-hour trip suitable for everyone. Prices: $750 for private charters with fishing (up to six people).",
      img: SaltySoul_half,
      url: "https://fareharbor.com/embeds/book/saltysoulcharters/items/324538/calendar/2023/11/?flow=1000849&asn=boozefreeinkeywest&full-items=yes&ref=ASN"
    },
    {
      title: "Salty Soul Charters - Sunset Cruise",
      description: "Experience the famous Key West sunset on a private 2-hour charter. Prices: $395 for up to six people.",
      img: SaltySoulSunset,
      url: "https://fareharbor.com/embeds/book/saltysoulcharters/items/324540/calendar/2023/11/?flow=1000849&asn=boozefreeinkeywest&full-items=yes&ref=ASN"
    },
    {
      title: "Restless Native Charters Overnight Expedition",
      description: "An overnight adventure on a private charter exploring the Marquesas with various activities included. Prices: $2,500 for up to 4 people, $3,500 for up to 6 people.",
      img: Charter2,
      url: "https://fareharbor.com/embeds/book/restlessnative/items/115709/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Restless Native Charters Full Day Sail",
      description: "An 8-hour private charter aboard a luxurious catamaran. Prices: $1,600 for up to 6 people on the ARIA, $2,600 on the ZODIAC.",
      img: Charter3,
      url: "https://fareharbor.com/embeds/book/restlessnative/items/113331/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Barebones Sandbar Boat Rentals",
      description: "Half or full-day boat rentals for exploring the waters of Key West. Prices start at $795.95 for a half-day charter including 8 guests.",
      img: Charter4,
      url: "https://fareharbor.com/embeds/book/lagerheadswatersports/items/399290/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Gulfstream Fishing Private Charter",
      description: "Private fishing charters for groups up to 45 people. Prices: $2000 for up to 25 people, $2400 for 26-45 people.",
      img: Charter5,
      url: "https://fareharbor.com/embeds/book/gulfstreamkeywest/items/34012/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Private Snorkel Tours",
      description: "Explore Key West's underwater world with a private snorkel tour for up to 6 people. Prices start at $649 for a four-hour charter.",
      img: Charter6,
      url: "https://fareharbor.com/embeds/book/keywestconchlifecharters/items/418429/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>Key West Private Charters - Exclusive Marine Adventures</title>
        <meta name="description" content="Experience the luxury and thrill of private charters in Key West. Explore our selection of private fishing trips, sunset cruises, snorkeling tours, and much more. Tailored for your exclusive adventure." />
        <meta name="keywords" content="Private Charters, Key West, Fishing Trips, Sunset Cruises, Snorkeling Tours, Marine Adventures, Exclusive Tours"/>
        <meta property="og:title" content="Key West Private Charters - Tailored Marine Experiences"/>
        <meta property="og:description" content="Discover the best private charters in Key West. From fishing adventures and sunset cruises to snorkeling tours, each charter offers a unique and personalized experience."/>
        <meta property="og:url" content="https://keysaver.app/private-charters"/>
        <meta property="og:image" content="https://keysaver.app/static/media/charter11.65d479919658e817fd7d.png"/>
        <link rel="canonical" href="https://keysaver.app/private-charters"/>
      </Helmet>

      <section className="mb-32 text-gray-800">
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences - Private Charters
        </h1>
        <div className="grid lg:grid-cols-3 gap-16">
          {articles.map((article, index) => (
            <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
              <a href={article.url} target="_blank">
                <img
                  src={article.img}
                  className="w-full h-64 object-cover rounded-t-lg"
                  alt={`Private Charter: ${article.title} - ${article.description}`} // More descriptive alt text
                />
                <div className="text-black p-6">
                  <h5 className="font-bold text-2xl mb-3 font-Poppins">
                    {article.title}
                  </h5>
                  <p className="mb-3">
                    <small>
                      {article.description}
                    </small>
                  </p>
                  <a
                    target="_blank"
                    href={article.url}
                    className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                  >
                    Book Now
                  </a>
                </div>
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default PrivateCharter;
