import React from "react";
import { Helmet } from 'react-helmet';
import BikeRental from "../../assets/Bikes/BikeRental.jpg";
import DeluxeScooter from "../../assets/Bikes/DeluxeScooter.jpg";
import GasCartSix from "../../assets/Bikes/GasCartSix.jpg";
import scooters from "../../assets/Bikes/scooters.png";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const Bikes = () => {
  const articles = [
    {
      title: "Barefoot Billy's Scooter Rental",
      description: "Explore Key West's hidden gems with Barefoot Billys scooters. Rates start at $65 for a 4-hour rental. Gas included, suitable for 18+ with a valid driver's license.",
      img: scooters,
      url: "https://fareharbor.com/embeds/book/barefootbillyskw/items/110684/calendar/2023/11/?asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&flow=no&branding=yes",
    },
    {
      title: "Key Lime Bike Tours Self Guided Tour",
      description: "The ONLY hourly bike rental in Key West! Discover the island on your own terms, rates starting at $4 for the first hour. Located conveniently for cruisers.",
      img: BikeRental,
      url: "https://fareharbor.com/embeds/book/keylimebiketours/items/407132/calendar/2024/01/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Mile 0 Rentals Deluxe 2 Passenger Scooter Rental",
      description: "Rent a deluxe 2 passenger scooter, perfect for touring the island at up to 40mph. Rates start at $105 for a 24-hour rental. Must be 18+ with a valid driver's license.",
      img: DeluxeScooter,
      url: "https://fareharbor.com/embeds/book/mile0rentals/items/297330/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Mile 0 Rentals Premium 6 Passenger Gas Cart Rental",
      description: "Enjoy Key West with a premium 6 passenger gas cart, complete with Bluetooth LED soundbar and custom features. Rates start at $170 for a 4-hour rental.",
      img: GasCartSix,
      url: "https://fareharbor.com/embeds/book/mile0rentals/items/297328/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>KeySaver - Explore Key West on Bikes, Scooters, and Golf Carts</title>
        <meta name="description" content="Rent and ride bikes, scooters, and golf carts across Key West. Barefoot Billy's Scooter Rental, Key Lime Bike Tours, Mile 0 Rentals, and more for the best island experience." />
        <meta name="keywords" content="Key West Bikes, Scooter Rentals, Golf Cart Rentals, Key West Travel, Island Tours"/>
        <meta property="og:title" content="KeySaver - Key West Bike and Scooter Adventures"/>
        <meta property="og:description" content="Discover Key West with our bike and scooter rentals. Enjoy premium gas cart rentals for groups, perfect for exploring the island's unique charm."/>
        <meta property="og:url" content="https://keysaver.app/bikes-scooters-golfcarts"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="URL_to_an_image_representing_bikes_page"/>
        <link rel="canonical" href="https://keysaver.app/bikes-scooters-golfcarts"/>
      </Helmet>

      <section className="mb-32 text-gray-800">
            <h1 className="text-3xl font-bold mb-12 text-center">
              Bikes - Scooters - Golf Carts
            </h1>
            <div className="grid lg:grid-cols-3 gap-16"> {/* Increased gap for better spacing */}
              {articles.map((article, index) => {
                return (
                  <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center"> {/* Added text-center */}
                    <a href={article.url} target="_blank" rel="noopener noreferrer">
                      <img
                        src={article.img}
                        className="w-full h-64 object-cover rounded-t-lg" // Fixed height for uniformity
                        alt={article.title}
                      />
                      <div className="text-black p-6"> {/* Moved text inside the a tag but outside the image */}
                        <h5 className="font-bold text-2xl mb-3 font-Poppins">
                          {article.title}
                        </h5>
                        <p className="mb-3">
                          <small>
                            {article.description}
                          </small>
                        </p>
                        <a
                          target="_blank"
                          href={article.url}
                          className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                          rel="noopener noreferrer"
                        >
                          Book Now
                        </a>
                      </div>
                    </a>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      );
    };
    
export default Bikes;   