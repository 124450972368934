import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet'; // Import Helmet
import headerImage from "../../assets/Misc/hero-image1.png";
import Carousel from '../Carousel';

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>KeySaver - Discover Key West's Best Charters & Tours</title>
        <meta name="description" content="Book top-rated Key West charters, jet skis, and tours with KeySaver. Experience the thrill of the Keys and support the local community and reef conservation efforts." />
        <meta name="keywords" content="Key West, Florida Keys, Charters, Jet Skis, Tours, Travel Booking, Local Experiences, Community Support, Reef Conservation"/>
        <meta property="og:title" content="KeySaver - Your Key West Adventure Starts Here"/>
        <meta property="og:description" content="Embark on unforgettable adventures in Key West with KeySaver. From exciting jet ski rides to immersive tours, support local community initiatives while exploring."/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://keysaver.app/"/>
        <meta property="og:image" content="https://keysaver.app/static/media/KeySaverLogo1.97ddb4cc3d6faed63fa4.webp"/>
      </Helmet>

      <section className="relative w-full h-screen flex items-center justify-center">
        <img src={headerImage} alt="A scenic view of a beach destination" className="absolute w-full h-full object-cover" />
        <div className="absolute bg-white bg-opacity-70 p-5 rounded-lg shadow-lg text-center max-w-md mx-auto w-full sm:max-w-lg md:max-w-xl lg:max-w-3xl">
          <h1 className="text-4xl mb-5 text-black">Welcome to KeySaver</h1>
          <p className="text-lg mb-3 text-black">Welcome to Key West! Our booking platform is your gateway to unforgettable experiences in Key West, and soon, across the Florida Keys. But we’re more than just a booking site; we’re a community-driven initiative.</p>
          <p className="text-lg mb-3 text-black">Meet Mermaid Molly, your AI Key West aficionado, ready to help you discover cool activities, spin tales, crack jokes, and even compose songs in true Key West style!</p>
          <hr className="border-black mb-3" />
          <p className="text-base sm:text-lg mb-3 text-black">Ready to dive in? Click below to start booking. Locals, hit ‘Sign Up’ to explore eligibility for our beta program.</p>
          <div className="flex justify-center space-x-4 flex-wrap">
           <Link to="/book" className="inline-block bg-mango text-white px-4 py-2 rounded mt-4 hover:bg-turquoise transition-colors duration-200 w-28 text-center">Book Now</Link>
           <Link to="/login" className="inline-block bg-turquoise text-white px-4 py-2 rounded mt-4 hover:bg-mango transition-colors duration-200 w-28 text-center">Sign Up</Link>
          </div>
        </div>
      </section>
      <div style={{ margin: '40px auto', width: '80%', maxWidth: '800px' }}>
        <iframe 
          src="https://chatthing.ai/bots/1b857592-a6ca-4914-8e97-f0be61c862da/embed" 
          width="100%" 
          height="600" 
          frameBorder="0" 
          allow="clipboard-write"
          title="Chatbot window"
          style={{ display: 'block', border: 'none' }}
        />
      </div>
      <div id="mc_embed_shell">
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">
          #mc_embed_signup{"{"}background:#fff; clear: left; font: 14px Helvetica,Arial,sans-serif; width: 600px;{"}"}
        </style>
        <div id="mc_embed_signup">
          <form
            action="https://app.us12.list-manage.com/subscribe/post?u=8e399b07e9c2c43dfef81c5de&amp;id=c848803db8&amp;f_id=008843e0f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_self"
            noValidate=""
          >
            <div id="mc_embed_signup_scroll">
              <h2>Subscribe</h2>
              <div className="indicates-required">
                <span className="asterisk">*</span> indicates required
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  required={true}
                  value=""
                  aria-label="Enter your email address"
                />
              </div>
              <div hidden={true}>
                <input type="hidden" name="tags" value="10526847,10526851" />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                aria-hidden={true}
                style={{ position: "absolute", left: "-5000px" }}
              >
                <input
                  type="text"
                  name="b_8e399b07e9c2c43dfef81c5de_c848803db8"
                  tabIndex="-1"
                  value=""
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                  value="Subscribe"
                  aria-label="Click to subscribe"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      </>
  );
};

export default HomePage;


