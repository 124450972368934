import React from "react";
import { Helmet } from 'react-helmet';
import Snork1 from "../../assets/Snorkling/Snork1.png";
import Snork2 from "../../assets/Snorkling/Snork2.png";
import Snork3 from "../../assets/Snorkling/Snork3.png";
import Snork4 from "../../assets/Snorkling/Snork4.png";
import Snork5 from "../../assets/Snorkling/Snork5.png";
import Snork6 from "../../assets/Snorkling/Snork6.png";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const Snorkling = () => {
  const articles = [
    {
      title: "Barefoot Billy's Dolphin Watch & Snorkel Tour",
      description: "Discover local dolphins and snorkel in Key West's wildlife sanctuary. Adults $89.99, Children $59, Toddlers Free, Seniors $79.99, Military $80. Duration: 3.5 hours. Experience the aquatic wonders of Key West, including a possible encounter with dolphins in their natural habitat.",
      img: Snork1,
      url: "https://fareharbor.com/embeds/book/barefootbillyskw/items/273057/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Danger Charters Afternoon Sail, Snorkel, Kayak & Sunset Excursion",
      description: "Relax on a historic schooner and enjoy kayaking, snorkeling, and the famous Key West sunset. Adults $109, Children $89, Toddlers Free. Duration: 4.5 hours. Limited to 20 guests for a personalized experience.",
      img: Snork2,
      url: "https://fareharbor.com/embeds/book/dangercharters/items/3996/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&sheet=310301&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Danger Charters Premium Day Sail, Snorkel &, Kayak with Lunch",
      description: "Enjoy a full day of sailing, snorkeling, and kayaking around Key West, with lunch included. Adults $134, Children $109, Toddlers Free. Duration: 6.5 hours. A maximum of 20 guests ensures an exclusive adventure.",
      img: Snork3,
      url: "https://fareharbor.com/embeds/book/dangercharters/items/3997/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&sheet=310301&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Floridays Snorkel Adventure",
      description: "Join a luxury snorkeling experience to North America's only living coral reef. Adults $79, Children $59. Duration: 3 hours. Includes beer, wine, and sodas with a limited number of guests for personal service.",
      img: Snork4,
      url: "https://fareharbor.com/embeds/book/floridayskeywest/items/37591/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Key West Conchlife Charters Private Snorkel Tours",
      description: "Private snorkeling tours for an intimate experience with nature. Price starts at $649 for a Four Hour Charter. Duration: 4, 6, or 8 hours. Perfect for small groups up to 6 people.",
      img: Snork5,
      url: "https://fareharbor.com/embeds/book/keywestconchlifecharters/items/418429/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Key West Eco Tours Java Cat • 4 hr Sail Kayak Snorkel Eco Tour",
      description: "Escape on a private eco tour aboard Java Cat, combining sailing, snorkeling, and kayaking. Price: $150 per person or $645 for a private 6 guest charter. Duration: 4 hours. A 6 passenger maximum for a more personalized tour.",
      img: Snork6,
      url: "https://fareharbor.com/embeds/book/keywestecotour/items/23942/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>Key West Snorkeling Adventures - Discover Underwater Marvels</title>
        <meta name="description" content="Explore the crystal-clear waters of Key West with our snorkeling tours. Encounter vibrant marine life and colorful coral reefs in an unforgettable underwater adventure." />
        <meta name="keywords" content="Snorkeling, Key West, Marine Life, Coral Reefs, Underwater Tours, Eco Tours, Dolphin Watch, Private Snorkel Tours"/>
        <meta property="og:title" content="Key West Snorkeling - A Dive into Nature's Splendor"/>
        <meta property="og:description" content="Join us for a snorkeling experience in Key West, where the wonders of marine life and coral reefs await. Perfect for families, adventurers, and nature enthusiasts."/>
        <meta property="og:url" content="https://keysaver.app/snorkeling"/>
        <meta property="og:image" content="https://keysaver.app/static/media/Snork1.073d35fb3bc312faa097.png"/>
        <link rel="canonical" href="https://keysaver.app/snorkeling"/>
      </Helmet>

      <section className="mb-32 text-gray-800">
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences - Snorkeling
        </h1>
        <div className="grid lg:grid-cols-3 gap-16">
          {articles.map((article, index) => (
            <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
              <a href={article.url} target="_blank">
                <img
                  src={article.img}
                  className="w-full h-64 object-cover rounded-t-lg"
                  alt={`Snorkeling Experience: ${article.title} - ${article.description}`} // More descriptive alt text
                />
                <div className="text-black p-6">
                  <h5 className="font-bold text-2xl mb-3 font-Poppins">
                    {article.title}
                  </h5>
                  <p className="mb-3">
                    <small>
                      {article.description}
                    </small>
                  </p>
                  <a
                    target="_blank"
                    href={article.url}
                    className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                  >
                    Book Now
                  </a>
                </div>
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Snorkling;
