import React from "react";
import { Link } from "react-router-dom";
import Airtour1 from "../assets/AirTour/airtour333.png";
import Bikes from "../assets/Bikes/scooters.png";
import Cultural from "../assets/HistoricTour/ernest.png";
import Snorkling from "../assets/Snorkling/coral1.png";
import Kayak from "../assets/Kayak/kayak11.png";
import Ski from "../assets/JetSkis/jetski.png";
import Charter from "../assets/CharterTour/charter11.png";
import KeyWestFood from "../assets/FoodTour/drink.png";
import HauntedTour from "../assets/HauntedTour/haunted1.png";
import HydroBike from "../assets/HydroBikes/Hydro_Bike.jpg";
import SunsetTours from "../assets/SunsetTours/DangerChartersWindWine.jpg";
import Fishing from "../assets/Fishing/Mahi.png";
import { Helmet } from "react-helmet"; // Import Helmet

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const BookExperiences = () => {
  const articles = [
    {
      title: "Fishing",
      Description: "Book your fishing charter here!",
      img: Fishing,
    },
    {
      title: "Sunset Tours",
      Description: "Enjoy a cocktail & Key Wests famous sunsets!",
      img: SunsetTours,
    },
    {
      title: "Jet Skis",
      Description: "Be sure to book your jet ski adventure here!",
      img: Ski,
    },
    {
      title: "Private Charters",
      Description:
        "Relax and sail into the magical waters of The Florida Keys!",
      img: Charter,
    },
    {
      title: "Dolphins - Snorkeling",
      Description:
        "You're taken out to watch the dolphins play & then a snorkel!",
      img: Snorkling,
    },
    {
      title: "Bikes - Scooters - Golf Carts",
      Description: "Rent a bike, cart or scooter to get around Key West!",
      img: Bikes,
    },
    {
      title: "Air Tours",
      Description: "Enjoy a complimentary champagne toast before your flight.",
      img: Airtour1,
    },
    {
      title: "Kayaks & Paddleboards",
      Description: "Hop aboard and create your own nature adventures!",
      img: Kayak,
    },
    {
      title: "Cultural - Historic Tours",
      Description:
        "Take this amazing guided tour through historical downtown Key West!",
      img: Cultural,
    },
    {
      title: "Food - Drink Tours",
      Description:
        "Enjoy all that Key West has to offer with the Southernmost Tour!",
      img: KeyWestFood,
    },
    {
      title: "Haunted Tours",
      Description:
        "Venture into the world of spirits & hear tales of the ghosts that inhabit our Isle of Bones.",
      img: HauntedTour,
    },
    {
      title: "Hydro Bikes, Hobie Cats, & Fun Cats",
      Description: "Get out on the water with Hydro bikes",
      img: HydroBike,
    },
  ];

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>KeySaver - Key West's Ultimate Booking for Adventures & Tours</title>
        <meta name="description" content="Explore and book a diverse range of adventures in Key West. From private charters to haunted tours, KeySaver offers unique experiences including jet ski rentals, dolphin tours, and cultural excursions." />
        <meta name="keywords" content="Key West, Adventure Booking, Charters, Jet Ski Rentals, Dolphin Tours, Snorkeling, Kayak Tours, Sunset Tours, Cultural Tours, Haunted Tours, Fishing Charters"/>
        <meta property="og:title" content="KeySaver - Key West's Ultimate Booking for Adventures & Tours"/>
        <meta property="og:description" content="Explore and book a diverse range of adventures in Key West. From private charters to haunted tours, KeySaver offers unique experiences including jet ski rentals, dolphin tours, and cultural excursions."/>
        <meta property="og:url" content="https://keysaver.app/book-experiences"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="https://keysaver.app/static/media/Mahi.070339667905befdc27a.png"/>
        <link rel="canonical" href="https://keysaver.app/book-experiences"/>
      </Helmet>

      <section className="mb-32 text-gray-800">
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences
        </h1>
        {/* Grid and Cards for Experiences */}
        <div className="grid lg:grid-cols-3 gap-16">
          {articles.map((article, index) => {
            const routePath = article.title.replace(/\s+/g, "");
            const camelCaseRoutePath =
              routePath.charAt(0).toLowerCase() + routePath.slice(1);

            return (
              <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
                <Link to={`/${camelCaseRoutePath}`}>
                  <img
                    src={article.img}
                    alt={`${article.title} in Key West`} // Descriptive alt text for each image
                    className="w-full h-64 object-cover rounded-t-lg"
                  />
                  <div className="text-black p-6">
                    <h5 className="font-bold text-2xl mb-3 font-Poppins">
                      {article.title}
                    </h5>
                    <p className="mb-3">
                      <small>{article.Description}</small>
                    </p>
                    <button className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200">
                      Explore
                    </button>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default BookExperiences;
