import React from "react";
import { Helmet } from 'react-helmet';
import Kayak1 from "../../assets/Kayak/Kayak1.png";
import Kayak2 from "../../assets/Kayak/Kayak2.png";
import Kayak3 from "../../assets/Kayak/Kayak3.png";
import Kayak4 from "../../assets/Kayak/Kayak4.png";
import Kayak5 from "../../assets/Kayak/Kayak5.png";
import Kayak6 from "../../assets/Kayak/Kayak6.png";
import Paddle1 from "../../assets/PaddleBoards/Paddle1.png";
import Paddle2 from "../../assets/PaddleBoards/Paddle2.png";
import Paddle4 from "../../assets/PaddleBoards/Paddle4.png";
import Paddle5 from "../../assets/PaddleBoards/Paddle5.png";
import Paddle6 from "../../assets/PaddleBoards/Paddle6.png";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const KayakTours = () => {
  const articles = [
    {
      title: "Nighttime Glow Kayak - Key West, FL",
      description:
        "Join us for a 45-minute magical nighttime glow kayak adventure suitable for all ages. Glide through Key West's waters in a clear kayak lit up with LED lights, observing the active marine life below. Rates start at $59 for a two-person kayak.",
      img: Kayak1,
      url: "https://fareharbor.com/embeds/book/glowpaddlekeywest/items/446542/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "2 Hour Kayak Eco Tour",
      description:
        "Explore the serene waters of Key West on a 2-hour kayak eco-tour with Lazy Dog. Encounter tropical fish, crabs, and various aquatic birds as you paddle through mangrove creeks. Rates start at $60 for adults, with discounts for children, residents, and military personnel.",
      img: Kayak2,
      url: "https://fareharbor.com/embeds/book/lazydog/items/3871/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Geiger Key Paddle Hut",
      description:
        "Escape the ordinary with a 2-hour guided kayak or SUP eco tour at Geiger Key Paddle Hut. Discover the mangrove ecosystem up close with experienced guides. Rates start at $75 for adults and $60 for children.",
      img: Kayak3,
      url: "https://fareharbor.com/embeds/book/keywestecotour/items/23945/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Led Lit Kayak Tour - Ibis Bay -KW",
      description:
        "Experience Ibis Bay like never before on a 1.5-hour LED-lit kayak tour. Spot nocturnal sea creatures under the stars in a kayak equipped with bright LED lights. Single kayak rates start at $59.95.",
      img: Kayak4,
      url: "https://fareharbor.com/embeds/book/ibisbaypaddlesports/items/186146/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Kayak Rental - Lazy Dog",
      description:
        "Rent a kayak from Lazy Dog and embark on a 4-hour self-guided adventure through Key West's waters. Maps included, starting at $30 for adults with discounts for residents, children, and military personnel.",
      img: Kayak5,
      url: "https://fareharbor.com/embeds/book/lazydog/items/3878/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Kayak Rental - Ibis Bay",
      description:
        "Explore Ibis Bay's calm waters at your own pace with a kayak rental. Enjoy a unique view with glass bottom kayaks, with 1-hour rentals starting at $20.",
      img: Kayak6,
      url: "https://fareharbor.com/embeds/book/ibisbaypaddlesports/items/186154/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Led Lit Paddleboard Tour",
      description:
        "Embark on a 1.5-hour guided paddleboard night tour at Ibis Bay. Navigate the waters with LED lights illuminating the active sea life below. Rates start at $59.95 for a single kayak.",
      img: Paddle1,
      url: "https://fareharbor.com/embeds/book/ibisbaypaddlesports/items/186146/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "PaddleYoga - Lazy Dog",
      description:
        "Experience paddleboard yoga in the protected mangroves of Key West. A 2-hour class suitable for all levels, starting at $40 for adults, with a local's discount available.",
      img: Paddle2,
      url: "https://fareharbor.com/embeds/book/lazydog/items/3876/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "2 Hour Eco Tour - Lazy Dog",
      description:
        "Join Lazy Dog for a 2-hour paddleboard eco tour through Key West's backcountry. Discover the rich marine life and mangrove ecosystems. Tours run daily with rates starting at $60 for adults.",
      img: Paddle4,
      url: "https://fareharbor.com/embeds/book/lazydog/items/3873/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "2-Hour Paddleboard Rental - Lazy Dog",
      description:
        "Rent a paddleboard for 2 hours from Lazy Dog and explore Key West at your own pace. Suitable for all skill levels, with rates starting at $30 for adults.",
      img: Paddle5,
      url: "https://fareharbor.com/embeds/book/lazydog/items/3880/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "2 - Hour Paddleboard Rental - Ibis Bay",
      description:
        "Enjoy the tranquil waters of Ibis Bay with a paddleboard rental. Discover vibrant sea life beneath you, with 1-hour rentals starting at $25.",
      img: Paddle6,
      url: "https://fareharbor.com/embeds/book/ibisbaypaddlesports/items/186157/calendar/2023/11/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>Key West Kayak and Paddleboard Tours - Explore Nature's Beauty</title>
        <meta name="description" content="Discover the natural wonders of Key West with our kayak and paddleboard tours. Experience the serene waters and vibrant marine life on our guided eco-tours and nighttime adventures." />
        <meta name="keywords" content="Kayak Tours, Paddleboard Tours, Key West, Eco Tours, Nighttime Adventures, Water Sports, Marine Life, Nature Exploration"/>
        <meta property="og:title" content="Key West Kayak and Paddleboard Tours - Unforgettable Water Experiences"/>
        <meta property="og:description" content="Embark on a journey through the serene waters of Key West with our kayak and paddleboard tours. Enjoy guided eco-tours, yoga sessions, and unique LED-lit nighttime adventures."/>
        <meta property="og:url" content="https://keysaver.app/kayak-paddleboard-tours"/>
        <meta property="og:image" content="https://keysaver.app/static/media/Paddle1.f3370c0bbb94ec05ff97.png"/>
        <link rel="canonical" href="https://keysaver.app/kayak-paddleboard-tours"/>
      </Helmet>

      <section className="mb-32 text-gray-800">
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences - Kayaks & PaddleBoards
        </h1>
        <div className="grid lg:grid-cols-3 gap-16">
          {articles.map((article, index) => (
            <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
              <a href={article.url} target="_blank">
                <img
                  src={article.img}
                  className="w-full h-64 object-cover rounded-t-lg"
                  alt={`Kayak/Paddleboard Tour: ${article.title} - ${article.description}`} // More descriptive alt text
                />
                <div className="text-black p-6">
                  <h5 className="font-bold text-2xl mb-3 font-Poppins">
                    {article.title}
                  </h5>
                  <p className="mb-3">
                    <small>
                      {article.description}
                    </small>
                  </p>
                  <a
                    target="_blank"
                    href={article.url}
                    className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                  >
                    Book Now
                  </a>
                </div>
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default KayakTours;