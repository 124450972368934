import React from "react";
import MissionImg from "../assets/Misc/mission1.webp";
import { Helmet } from 'react-helmet';

const MissionPage = () => {
  return (
    <div className="container my-24 px-6 mx-auto text-center">
       <Helmet>
        <title>Our Mission - KeySaver</title>
        <meta name="description" content="Discover KeySaver's mission to transform the Florida Keys into a digital hub. Learn about our commitment to community, technology, and sustainable tourism." />
        <meta property="og:title" content="KeySaver's Mission"/>
        <meta property="og:description" content="KeySaver is dedicated to creating a digital hub in the Florida Keys, promoting sustainable tourism and supporting the local community."/>
        <meta property="og:image" content={MissionImg}/>
        <meta property="og:url" content="https://keysaver.app/mission"/>
        <link rel="canonical" href="https://keysaver.app/mission"/>
      </Helmet>
      
      <section className="mt-32 mb-32 text-gray-800">
        <div className="block rounded-lg shadow-lg bg-white">
          <div className="flex flex-col items-center">
            <div className="w-full">
              <img
                src={MissionImg}
                alt="Mission"
                className="w-full h-auto rounded-t-lg"
              />
            </div>
            <div className="w-full">
              <div className="px-6 py-12">
                <h1 className="text-3xl font-bold mb-2 text-blue-600">
                  KeySaver's Mission
                </h1>
                <div className="text-gray-700 mb-6 space-y-4">
                  <p>KeySaver is shaping a digital hub in the Florida Keys, morphing locals into digital concierges who assist tourists in discovering and booking authentic local experiences with a simple QR code scan.</p>
                  
                  <p>Our journey begins with a user-friendly web app where locals sign up, get verified, and step into a digital concierge role. The platform fosters interactions between tourists and locals, leading to bookings. The commissions generated are shared among our local concierges, embodying our community-centric philosophy of "One Human Family."</p>
                  
                  <p>Central to KeySaver is Mermaid Molly, an AI chatbot aiding users in exploring local experiences and serving as an advertising conduit. Our revenue stems from bookings and advertising, laying the groundwork for a sustainable, community-driven business model.</p>
                  
                  <p>As we move forward, we're introducing live streaming for local musicians, ad-supported eco-friendly golf cart rides, and an online marketplace for local goodies. Our technological roadmap is vibrant, with plans for augmented reality, blockchain, cryptocurrency, and virtual reality integrations.</p>
                  
                  <p>We're also gearing up for a data collection initiative utilizing our platform's assets like golf carts and boats. This data, especially from the quirky and unique traffic scenarios in Key West, will be a treasure trove for AI autonomous vehicle research and safety advancements.</p>
                  
                  <p>KeySaver is more than an app; it's a movement towards a more collaborative, eco-conscious community, bolstering the local economy, enhancing the tourist experience in the Florida Keys, and contributing to broader technological and environmental advancements.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MissionPage;