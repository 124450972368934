import React from "react";
import { Helmet } from "react-helmet";
import Barefootbillys from "../../assets/SunsetTours/BareFootBillys.jpg";
import DangerChartersPrivateSunset from "../../assets/SunsetTours/DangerChartersPrivateSunset.jpg";
import DangerChartersWindWine from "../../assets/SunsetTours/DangerChartersWindWine.jpg";
import StarlightSail from "../../assets/SunsetTours/StarlightSail.jpg";
import SunsetSipSail from "../../assets/SunsetTours/SunsetSipSail.jpg";
import SunsetTikiCruise from "../../assets/SunsetTours/SunsetTikiCruise.jpg";


const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const SunsetTours = () => {
  const articles = [
    {
      title: "Sushi & Cocktails Sunset Cruise",
      description: "Celebrate the end of the day in style with a spectacular Key West sunset harbor cruise. Prices: Adults $89, Children $59, Seniors/Military $80. Duration: 2 Hours.",
      img: Barefootbillys,
      url: "https://fareharbor.com/embeds/book/barefootbillyskw/items/273058/calendar/2023/08/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes"
    },
    {
      title:"Sunset Tiki Boat Cruise",
      description:"Enjoy a two-hour cruise to view the world-famous Key West sunset. Prices: $74.95 per person, $425 for private tour up to 6 passengers. Duration: 2 Hours.",
      img: SunsetTikiCruise,
      url: "https://fareharbor.com/embeds/book/keywestpromotions/items/263983/calendar/2023/08/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title:"Wind & Wine Sunset Sail",
      description:"An upscale, yet relaxed and intimate, sunset sail. Prices: Adults $109. Duration: 2 Hours.",
      img: DangerChartersWindWine,
      url: "https://fareharbor.com/embeds/book/dangercharters/items/3994/calendar/2023/08/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&sheet=310301&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title:"Sunset Sip & Sail",
      description:"Catch the Key West Sunset with Live Music & Open Well Bar! Prices: Adults $69.95, Youth $39.95. Duration: 2 Hours.",
      img: SunsetSipSail,
      url: "https://fareharbor.com/embeds/book/sunsetwatersportskeywest/items/263707/calendar/2023/08/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title:"Starlight Sail",
      description:"Sailing under the stars. Prices: $595 for private charter up to 6 guests. Duration: 1.5 Hours.",
      img: StarlightSail,
      url: "https://fareharbor.com/embeds/book/sunsetsailkeywest/items/1206/calendar/2023/08/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
];

return (
  <div className="container my-24 px-6 mx-auto">
    <Helmet>
      <title>Key West Sunset Cruises - Breathtaking Views & Unforgettable Experiences</title>
      <meta name="description" content="Experience the majestic Key West sunsets with our variety of sunset cruises. Whether it's a romantic sail or a fun-filled boat party, we offer the perfect end to your day." />
      <meta name="keywords" content="Sunset Cruises, Key West, Sailing, Boat Tours, Sunset Sailing, Romantic Cruises, Party Cruises"/>
      <meta property="og:title" content="Key West Sunset Cruises - Unwind with Stunning Sunset Views"/>
      <meta property="og:description" content="Join us on our Key West sunset cruises for an experience like no other. Enjoy the beautiful sunset, delicious drinks, and a relaxing atmosphere as you sail along the coast."/>
      <meta property="og:url" content="https://keysaver.app/sunset-cruises"/>
      <meta property="og:image" content="https://keysaver.app/static/media/DangerChartersWindWine.69d0ffaf159a8ddfc0aa.jpg"/>
      <link rel="canonical" href="https://keysaver.app/sunset-cruises"/>
    </Helmet>

    <section className="mb-32 text-gray-800">
      <h1 className="text-3xl font-bold mb-12 text-center">
        Book a Sunset Cruise
      </h1>
      <div className="grid lg:grid-cols-3 gap-16">
        {articles.map((article, index) => (
          <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
            <a href={article.url} target="_blank">
              <img
                src={article.img}
                className="w-full h-64 object-cover rounded-t-lg"
                alt={`Sunset Cruise: ${article.title} - ${article.description}`} // More descriptive alt text
              />
              <div className="text-black p-6">
                <h5 className="font-bold text-2xl mb-3 font-Poppins">
                  {article.title}
                </h5>
                <p className="mb-3">
                  <small>
                    {article.description}
                  </small>
                </p>
                <a
                  target="_blank"
                  href={article.url}
                  className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                >
                  Book Now
                </a>
              </div>
            </a>
          </div>
        ))}
      </div>
    </section>
  </div>
);
};

export default SunsetTours;