import React, { useEffect, useState } from "react";
import { AiOutlineUser, AiOutlineHome, AiOutlineMail, AiOutlineQrcode, AiOutlineClose } from "react-icons/ai";
import { MdOutlineBookmarks } from "react-icons/md";
import { IoRocketOutline } from "react-icons/io5";
import { Menu, Transition } from "@headlessui/react";
import logo from "../../assets/Misc/KeySaverLogo1.webp";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from '../../auth/AuthContext';
import QRCode from "qrcode.react";
import { checkVerified } from '../../lib/checkVerified';
import { FaHandshake } from 'react-icons/fa';


const Header = () => {
  const navigate = useNavigate();
  const { user, signOut } = useAuth();
  const [scrolling, setScrolling] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [referralLink, setReferralLink] = useState("");

  useEffect(() => {
    if (user) {
      setReferralLink(`https://keysaver.app/book`);
      checkVerified(user.id).then(verified => {
        setIsVerified(verified);
      });
    }
  }, [user]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0 && !scrolling) {
        setScrolling(true);
      } else if (scrollTop === 0 && scrolling) {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrolling]);

  const handleLogout = () => {
    signOut();
    navigate("/login");
  };

  const handleUserIconClick = () => {
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  const headerClass =
    "w-full fixed top-0 z-10 transition-all duration-200 ease-in-out bg-white bg-opacity-70 text-black py-4";

    return (
      <>
        <header className={headerClass}>
      <div className="container mx-auto px-4 flex items-center justify-between">
        <div className="flex-shrink-0 w-32 sm:w-40 md:w-56 lg:w-64 xl:w-80 mr-8">
          <a href="/">
            <img src={logo} alt="KeySaver Logo" className="w-full h-auto" />
          </a>
        </div>
        <nav>
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div className="inline-flex space-x-2 items-center">
                  {user && isVerified && (
                    <button type="button" className="text-2xl" aria-label="Open QR code" onClick={() => setShowQRCode(true)}>
                      <AiOutlineQrcode />
                    </button>
                  )}
                  {showQRCode && (
                    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                      <div className="bg-white p-8 rounded-lg w-64 h-64 flex flex-col items-center justify-center relative">
                        <QRCode value={referralLink || "default_value"} size={128} />
                        <button type="button" className="absolute top-2 right-2" aria-label="Close QR code" onClick={() => setShowQRCode(false)}>
                          <AiOutlineClose />
                        </button>
                      </div>
                    </div>
                  )}
                  <Menu.Button className="inline-flex flex-col justify-center items-center w-8 h-8">
                    <span className="w-5 h-0.5 mb-1 bg-gray-600"></span>
                    <span className="w-5 h-0.5 mb-1 bg-gray-600"></span>
                    <span className="w-5 h-0.5 bg-gray-600"></span>
                  </Menu.Button>
                  <button type="button" className="text-2xl" aria-label="User menu" onClick={handleUserIconClick}>
                    <AiOutlineUser />
                  </button>
                </div>
                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white bg-opacity-80 ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        <a href="/" className="flex items-center block px-4 py-2 text-sm text-black hover:bg-opacity-100">
                          <AiOutlineHome className="mr-2" /> Home
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href="/book" className="flex items-center block px-4 py-2 text-sm text-black hover:bg-white">
                          <MdOutlineBookmarks className="mr-2" /> Book
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href="https://keysaver.app/articles" className="flex items-center block px-4 py-2 text-sm text-black hover:bg-white">
                          <AiOutlineQrcode className="mr-2" /> Articles
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href="/about" className="flex items-center block px-4 py-2 text-sm text-black hover:bg-white">
                          <IoRocketOutline className="mr-2" /> Mission
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href="/contact" className="flex items-center block px-4 py-2 text-sm text-black hover:bg-white">
                          <AiOutlineMail className="mr-2" /> Contact Us
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href="/ChatbotPricing" className="flex items-center block px-4 py-2 text-sm text-black hover:bg-white">
                          <FaHandshake className="mr-2" /> Partner
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <button type="button" onClick={handleUserIconClick} className="flex items-center w-full px-4 py-2 text-sm text-black hover:bg-white">
                          <AiOutlineUser className="mr-2" /> {user ? "Dashboard" : "Login"}
                        </button>
                      </Menu.Item>
                      {user && (
                        <Menu.Item>
                          <button type="button" onClick={handleLogout} className="flex items-center w-full px-4 py-2 text-sm text-black hover:bg-white">
                            Logout
                          </button>
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </nav>
      </div>
      </header>
    </>
  );
};

export default Header;