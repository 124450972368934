import React from "react";
import { Helmet } from 'react-helmet';
import Food1 from "../../assets/FoodTour/Food1.png";
import Food2 from "../../assets/FoodTour/Food2.png";
import Food3 from "../../assets/FoodTour/Food3.png";
import Food4 from "../../assets/FoodTour/Food4.png";
import Food5 from "../../assets/FoodTour/Food5.png";
import Food6 from "../../assets/FoodTour/Food6.png";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const FoodDrinks = () => {
  const articles = [
    {
      title: "The Cocktail Crawl",
      description:
        "Savor a 3-hour escapade through Key West's finest bars, sampling 5 craft cocktails and 2 generous tastings along the way. Adults 21+ can join the fun at $79, while non-alcoholic options are available for $39. Navigate the local hot spots and enjoy historical insights with a complimentary tour booklet full of coupons.",
      img: Food1,
      url: "https://fareharbor.com/embeds/book/keywestfoodtours/items/89529/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Southernmost Food Tasting & Cultural Walking",
      description:
        "Embark on a 3-hour journey tasting Key West's iconic foods including Key Lime Pie and Conch Fritters. Adult tickets start at $79, children at $49, with private group rates beginning at $990 for up to 10 guests. Delve into the history of Cuban cuisine and its influence on the island's culture.",
      img: Food2,
      url: "https://fareharbor.com/embeds/book/keywestfoodtours/items/89521/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Historic Seaport Food & Cultural Walking",
      description:
        "Explore the rich maritime history of Key West on a 3-hour tour featuring 5+ tastings. Adults pay $79, including an alcoholic beverage, and children are $49. Discover the fresh local ingredients that make up the historic seaport's vibrant culinary scene.",
      img: Food3,
      url: "https://fareharbor.com/embeds/book/keywestfoodtours/items/89528/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Hemingway Food & Walking",
      description:
        "Immerse in Hemingway's world on a 2-hour tour, where you'll visit places he lived and played. Priced at $98 for adults and $69 for children, the tour includes three food tastings and a wealth of knowledge about this legendary author's life in Key West.",
      img: Food4,
      url: "https://fareharbor.com/embeds/book/hemingwayinkeywesttours/items/287631/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Bone Island Haunted Pub Crawl",
      description:
        "Join a chilling 2-hour journey through Bone Island's haunted pubs for $24.95. For adults 21 and over, this tour promises 'spirited fun' with ghostly stories and a complimentary souvenir T-shirt.",
      img: Food5,
      url: "https://fareharbor.com/embeds/book/keywestpromotions/items/203673/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Key West Pub Crawl",
      description:
        "Experience Key West's famous bars with a 2.5-hour guided pub crawl. For $34.95, enjoy 5 cocktails, contests, and comedy, along with a beautiful souvenir T-Shirt. This adventure is for adults 21+ and runs several nights a week.",
      img: Food6,
      url: "https://fareharbor.com/embeds/book/keywestpromotions/items/203633/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];  

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>KeySaver - Explore Key West's Best Food and Drink Experiences</title>
        <meta name="description" content="Join KeySaver to taste Key West's best culinary delights. From historical walking food tours to haunted pub crawls, discover the island's rich flavors and spirits." />
        <meta name="keywords" content="Key West Food Tours, Key West Drink Tours, Culinary Experiences, Cocktail Crawl, Southernmost Food Tasting, Haunted Pub Crawl, Key West Cuisine, Island Flavors"/>
        <meta property="og:title" content="KeySaver - Key West Culinary and Spirits Adventure"/>
        <meta property="og:description" content="Sip and savor your way through Key West with KeySaver's guided food and drink tours. Experience local cuisine and cocktails for an authentic taste of the island."/>
        <meta property="og:url" content="https://keysaver.app/food-drinks"/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content="https://keysaver.app/static/media/drink.e000748aa75c10febe07.png"/>
        <link rel="canonical" href="https://keysaver.app/food-drinks"/>
      </Helmet>

      <section className="mb-32 text-gray-800"></section>
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences - Food and Drink Tours
        </h1>
        <div className="grid lg:grid-cols-3 gap-16"> {/* Increased gap for better spacing */}
          {articles.map((article, index) => (
            <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center"> {/* Added text-center */}
              <a href={article.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={article.img}
                  className="w-full h-64 object-cover rounded-t-lg" // Fixed height for uniformity
                  alt={`Food and Drink Tours: ${article.title} - ${article.description}`}
                />
                <div className="text-black p-6"> {/* Moved text inside the a tag but outside the image */}
                  <h5 className="font-bold text-2xl mb-3 font-Poppins">
                    {article.title}
                  </h5>
                  <p className="mb-3">
                    <small>
                      {article.description}
                    </small>
                  </p>
                  <a
                    target="_blank"
                    href={article.url}
                    className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                    rel="noopener noreferrer"
                  >
                    Book Now
                  </a>
                </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FoodDrinks;
