import React from "react";
import { Helmet } from 'react-helmet';
import Barefoot_Fishing from "../../assets/Fishing/Barefoot_Fishing.jpg";
import SaltySoulFishing from "../../assets/Fishing/SaltySoulFishing.jpg";
import SaltySoulFishing_half from "../../assets/Fishing/SaltySoulFishing_half.jpg";
import Gulfstream from "../../assets/Fishing/Gulfstream.jpg";
import Gulfstream_PM from "../../assets/Fishing/Gulfstream_PM.jpg";
import Gulfstream_Private from "../../assets/Fishing/Gulfstream_Private.jpg";
import CS_half from "../../assets/Fishing/CS_half.jpg";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const Fishing = () => {
    const articles = [
        {
            title: "Barefoot Fishing",
            description: "Private fishing charter from the Garrison Bight Marina. Targets tarpon, shark, barracudas, bonefish, snook, and more. Prices: $700 for 4 hours, $900 for 6 hours, $1,200 for 8 hours. Duration: Options of 4, 6, or 8 hours. Accommodates up to 4 anglers.",
            img: Barefoot_Fishing,
            url: "https://fareharbor.com/embeds/book/barefootbillyskw/items/402665/calendar/2023/09/?flow=no&language=en-us&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes&g4=yes"
        },
        {
            title: "Salty Soul Fishing",
            description: "Private 6-hour fishing trip suitable for all ages. Prices: $1,025 for private charters with fishing (up to six people). Duration: 6 hours.",
            img: SaltySoulFishing,
            url: "https://fareharbor.com/embeds/book/saltysoulcharters/items/324531/calendar/2023/09/?asn=boozefreeinkeywest&full-items=yes&ref=ASN&flow=1000849"
        },
        {
            title: "Salty Soul Fishing - Half Day",
            description: "Private 4-hour fishing trip suitable for all ages. Prices: $700 for private charters with fishing (up to six people). Duration: 4 hours.",
            img: SaltySoulFishing_half,
            url: "https://fareharbor.com/embeds/book/saltysoulcharters/items/324538/calendar/2023/09/?flow=1000849&asn=boozefreeinkeywest&full-items=yes&ref=ASN"
        },
        {
            title: "Gulfstream Fishing",
            description: "Shared fishing charter departing daily at 10 am and returning at 4 pm. Bottom fishing targeting snapper, grouper, and more. Prices: Adults (13+): $67.95, Children under 12: $42.95, Infants under 5: $39.95, Rider not fishing: $45.95. Duration: 6 hours.",
            img: Gulfstream,
            url: "https://fareharbor.com/embeds/book/gulfstreamkeywest/items/34004/calendar/2023/09/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes&g4=yes"
        },
        {
            title: "Gulfstream Night Fishing",
            description: "Shared evening fishing trip departing at 7:30 pm and returning at midnight. Prices: Adults (13+): $67.95, Children under 12: $42.95, Infants under 5: $39.95, Rider not fishing: $45.95. Duration: 4.5 hours.",
            img: Gulfstream_PM,
            url: "https://fareharbor.com/embeds/book/gulfstreamkeywest/items/34009/calendar/2024/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes&g4=yes"
        },
        {
            title: "Private Fishing Charter",
            description: "Private fishing charter suitable for up to six people. Prices: Adults (13+): $67.95, Children under 12: $42.95, Infants under 5: $39.95, Rider not fishing: $45.95. Duration: 4 hours.",
            img: Gulfstream_Private,
            url: "https://fareharbor.com/embeds/book/gulfstreamkeywest/items/34012/calendar/2023/09/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes&g4=yes"
        },
        {
            title: "Half Day Fishing",
            description: "Book your half day fishing trip suitable for all ages!",
            img: CS_half,
            url: "https://fareharbor.com/embeds/book/keywestseafishing/items/266945/calendar/2023/09/?flow=no&language=en-us&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes&g4=yes"
        },
    ];

    return (
      <main className="container my-24 px-6 mx-auto">
          <Helmet>
              <title>KeySaver - Premier Fishing Charters and Catches in Key West</title>
              <meta name="description" content="Book the best Key West fishing charters with KeySaver. Target hogfish, grouper, tarpon, mahimahi, snapper, lobster, and more for an unforgettable fishing experience." />
              <meta name="keywords" content="Key West Fishing, Hogfish, Grouper, Tarpon, Mahi Mahi, Snapper, Lobster, Crab, Fishing Charters, Fishing Trips, Barefoot Fishing, Salty Soul Fishing, Gulfstream Fishing"/>
              <meta property="og:title" content="KeySaver - Discover Key West's Finest Fishing Charters"/>
              <meta property="og:description" content="Experience the thrill of Key West fishing with KeySaver. Catch hogfish, grouper, tarpon, mahimahi, and more on our premier fishing charters."/>
              <meta property="og:url" content="https://keysaver.app/fishing"/>
              <meta property="og:type" content="website"/>
              <meta property="og:image" content="https://keysaver.app/static/media/Mahi.070339667905befdc27a.png"/> {/* Replace with your actual URL */}
              <link rel="canonical" href="https://keysaver.app/fishing"/>
          </Helmet>

          <header className="mb-32 text-gray-800">
              <h1 className="text-3xl font-bold mb-12 text-center">
                  Private - Shared - Fishing Charters
              </h1>
          </header>
          <section className="grid lg:grid-cols-3 gap-16">
            {articles.map((article, index) => {
              return (
                <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
                    <a href={article.url} target="_blank" rel="noopener noreferrer">
                        <img
                            src={article.img}
                            alt={`${article.title} - ${article.description}`} // Descriptive alt text for each image
                            className="w-full h-64 object-cover rounded-t-lg"
                            loading="lazy"
                        />
                        <div className="text-black p-6">
                          <h2 className="font-bold text-2xl mb-3">
                            {article.title}
                          </h2>
                          <p className="mb-3">
                            <small>
                              {article.description}
                            </small>
                          </p>
                          <a
                            target="_blank"
                            href={article.url}
                            className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                            rel="noopener noreferrer"
                          >
                            Book Now
                          </a>
                        </div>
                    </a>
                </div>
              );
            })}
          </section>
      </main>
    );
};

export default Fishing;
