import React from 'react'
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import HomePage from "./components/HomePage/HomePage";
import Login from "./pages/Login";
import ChatbotPricing from "./pages/ChatbotPricing";
import BookExperiences from "./pages/BookExperiences";
import AirTours from "./pages/BookTours/AirTours";
import Bikes from "./pages/BookTours/Bikes";
import Fishing from "./pages/BookTours/Fishing";
import FoodDrinks from "./pages/BookTours/FoodDrinks";
import HauntedTours from "./pages/BookTours/HauntedTours";
import HistoricTours from "./pages/BookTours/HistoricTours";
import HydroBikes from "./pages/BookTours/HydroBikes";
import JetSkis from "./pages/BookTours/JetSkisTours";
import KayakTours from "./pages/BookTours/KayakTours";
import PrivateCharter from "./pages/BookTours/PrivateCharter";
import Snorkling from "./pages/BookTours/Snorkling";
import SunsetTours from "./pages/BookTours/SunsetTours";
import MissionPage from "./pages/MissionPage";
import Dashboard from './pages/DashBoard';
import ContactUs from './pages/ContactUs';
import "./tailwind.css";
import {AuthProvider} from './auth/AuthProvider';
import ProtectedRoute from './auth/ProtectedRoute';

function App() {
  useEffect(() => {
    window.chatThingConfig = {
      colour: { "widgetColour": "rgb(8, 82, 155)", "widgetColourInverted": "#fff" },
      icon: {}
    };

    // Create the script tag and set its attributes
    const script = document.createElement("script");
    script.src = "https://chatthing.ai/chat-widget.js";
    script.type = "text/javascript";
    script.id = "1b857592-a6ca-4914-8e97-f0be61c862da";
    script.async = true;
    script.defer = true;

    // Append the script tag to the document head
    document.head.appendChild(script);

    // Optional: remove the script tag when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array ensures this runs once after mounting

  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/chatbotpricing" element={<ChatbotPricing />} />
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard/></ProtectedRoute>}/>
            <Route path="/book" element={<BookExperiences />} />
            <Route path="/about" element={<MissionPage />} />
            <Route path="/airTours" element={<AirTours />} />
            <Route path="/bikes-scooters-golfcarts" element={<Bikes />} />
            <Route path="/fishing" element={<Fishing />} />
            <Route path="/food-DrinkTours" element={<FoodDrinks />} />
            <Route path="/hauntedTours" element={<HauntedTours />} />
            <Route path="/cultural-HistoricTours" element={<HistoricTours />} />
            <Route path="/hydroBikes,HobieCats,&FunCats" element={<HydroBikes />} />
            <Route path="/jetSkis" element={<JetSkis />} />
            <Route path="/kayaks&Paddleboards" element={<KayakTours />} />
            <Route path="/dolphins-Snorkeling" element={<Snorkling />} />
            <Route path="/privateCharters" element={<PrivateCharter />} />
            <Route path="/sunsettours" element={<SunsetTours/>}/> 
            <Route path="/contact" element={<ContactUs />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
