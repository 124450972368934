import React from "react";
import { Helmet } from 'react-helmet';
import HistoricTour1 from "../../assets/HistoricTour/HistoricTour1.png";
import HistoricTour2 from "../../assets/HistoricTour/HistoricTour2.png";
import HistoricTour3 from "../../assets/HistoricTour/HistoricTour3.png";
import HistoricTour4 from "../../assets/HistoricTour/HistoricTour4.png";
import HistoricTour5 from "../../assets/HistoricTour/HistoricTour5.png";
import HistoricTour6 from "../../assets/HistoricTour/HistoricTour6.png";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const HistoricTours = () => {
  const articles = [
    {
      title: "2.5 hour Private Tiki Bike Ride",
      description: "Experience a unique 2.5-hour journey through the heart of Key West on a private Tiki Bike. As you traverse the Old Town, your guide will share tales of the island's history and culture. Enjoy the VIP treatment with first-row seats, and savor a slice of Kermit's famous Key Lime Pie. The tour is perfect for groups of up to 3, priced at $224.99, and includes a complimentary bottle of water.",
      img: HistoricTour1,
      url: "https://fareharbor.com/embeds/book/floridakeysventures/items/217057/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&sheet=252642&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Jimmy Buffett Tour",
      description: "Delve into the world of Jimmy Buffett on this 1.5-hour tour, exploring the iconic spots that inspired his music. For $78, adults can enjoy a walking tour complete with a slice of key lime pie, water, and an alcoholic beverage. Children’s tickets are available for $39, and those 5 and under join free. Learn about Buffett's Key West, from his first gig to his favorite hangouts.",
      img: HistoricTour2,
      url: "https://fareharbor.com/embeds/book/hemingwayinkeywesttours/items/310401/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Hemingway Tour",
      description: "Take a step back in time with a 65-minute tour that weaves through Ernest Hemingway's Old Town haunts. Priced at $68 for adults and $28 for children, this tour offers a glimpse into Hemingway's life in Key West, visiting the places where he lived and wrote. Children 5 and under can enjoy the tour free of charge.",
      img: HistoricTour3,
      url: "https://fareharbor.com/embeds/book/hemingwayinkeywesttours/items/314425/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "2-Hour Historic District Walking Tour",
      description: "Embark on a 2-hour guided journey through Key West's Historic District. Learn about the island's early industries and local history for $46.99 per adult. Our expert guides will reveal the stories behind the charming architecture and vibrant streets of Old Town Key West.",
      img: HistoricTour4,
      url: "https://fareharbor.com/embeds/book/floridakeysventures/items/203230/calendar/2023/08/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&sheet=252642&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Private Southern Most Historic Walking Tour",
      description: "Enjoy an exclusive 2-hour walking tour through Key West's Old Town for up to 6 people, priced at $239.99. Discover the island's rich history and culture, from shipwrecking to cigar making, and learn about Key West's pivotal role in historical events.",
      img: HistoricTour5,
      url: "https://fareharbor.com/embeds/book/floridakeysventures/items/196883/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&sheet=252642&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Southernmost Historical Walking Tour",
      description: "Join a 1 hour and 45 minutes walking tour for $39.99 per adult and $29.99 per child, starting at the famous Kino Sandals. Visit landmarks and learn about the local culture as you make your way to the Southernmost Point. Toddlers 6 and under are welcome for free.",
      img: HistoricTour6,
      url: "https://fareharbor.com/embeds/book/floridakeysventures/items/176775/calendar/2023/08/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&sheet=252642&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];  

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>Key West Historic Tours - Explore the Rich History</title>
        <meta name="description" content="Discover the fascinating history of Key West with our guided Historic Tours. From Ernest Hemingway's haunts to Jimmy Buffett's Key West, immerse yourself in the island's unique past." />
        <meta name="keywords" content="Key West, Historic Tours, Ernest Hemingway, Jimmy Buffett, Old Town Key West, History, Culture, Walking Tours"/>
        <meta property="og:title" content="Key West Historic Tours - A Journey Through Time"/>
        <meta property="og:description" content="Join us on a journey through Key West's rich history. Explore the stories and landmarks that define the island's unique cultural heritage."/>
        <meta property="og:url" content="https://keysaver.app/historic-tours"/>
        <meta property="og:image" content="https://keysaver.app/static/media/ernest.4d91483db8a30b76fc62.png"/>
        <link rel="canonical" href="https://keysaver.app/historic-tours"/>
      </Helmet>

      <section className="mb-32 text-gray-800">
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences - Historic Tours
        </h1>
        <div className="grid lg:grid-cols-3 gap-16">
          {articles.map((article, index) => (
            <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
              <a href={article.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={article.img}
                  className="w-full h-64 object-cover rounded-t-lg"
                  alt={`Historic Tour: ${article.title} - ${article.description}`} // More descriptive alt text
                />
                <div className="text-black p-6">
                  <h5 className="font-bold text-2xl mb-3 font-Poppins">
                    {article.title}
                  </h5>
                  <p className="mb-3">
                    <small>
                      {article.description}
                    </small>
                  </p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={article.url}
                    className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                  >
                    Book Now
                  </a>
                </div>
              </a>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HistoricTours;