// Login.js

import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import supabase from "../utils/supabase";
import { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';

export default function Login() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      const { data: { session: currentSession } } = await supabase.auth.getSession();
      setSession(currentSession);
    };

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, newSession) => {
      setSession(newSession);
    });

    fetchSession();

    return () => subscription.unsubscribe();
  }, []);

  async function signOut() {
    await supabase.auth.signOut();
  }

  const getURL = () => {
    let url =
      process.env.NEXT_PUBLIC_SITE_URL ??
      process.env.NEXT_PUBLIC_VERCEL_URL ??
      window.location.origin ??
      'http://localhost:3000/';

    url = url.includes('http') ? url : `https://${url}`;
    url = url.charAt(url.length - 1) === '/' ? url : `${url}/`;
    return url;
  };

  return (
    <div className="relative w-full h-screen flex flex-col">
      <Helmet>
        <title>Login - KeySaver</title>
        <meta name="description" content="Log in to your KeySaver account to access personalized services and features." />
      </Helmet>
      
      {session ? (
        <div className="flex flex-col h-full justify-center items-center gap-2">
          <div>Logged in!</div>
          <a href="/dashboard">Dashboard</a>
          <button onClick={signOut}>Sign out</button>
        </div>
      ) : (
        <div className="flex flex-col h-full justify-center items-center">
          <Auth
            supabaseClient={supabase}
            appearance={{theme: ThemeSupa}}
            providers={['google', 'discord']}
            redirectTo={`${getURL()}dashboard/`}
          />
        </div>
      )}
    </div>
  );
};
