import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';


const ChatbotPricing = () => {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "ProfessionalService",
    "serviceType": "Advertising and Marketing",
    "provider": {
      "@type": "Organization",
      "name": "KeySaver",
      "image": "https://keysaver.app/static/media/KeySaverLogo1.97ddb4cc3d6faed63fa4.webp",
      "url": "https://keysaver.app"
    },
    "description": "Partner with KeySaver for advertising and marketing services, leveraging chatbot technology with Mermaid Molly.",
    "areaServed": {
      "@type": "Place",
      "name": "Key West"
    }
  };

return (
    <div className="flex flex-col items-center justify-center min-h-screen pt-24" data-chatbot-section="ChatbotPricing">
      <Helmet>
        <title>Comprehensive Advertising and Partner Opportunities with KeySaver - Boost Your Business with Mermaid Molly</title>
        <meta name="description" content="Discover premier advertising opportunities with KeySaver, offering SEO-optimized content creation, social media promotion, and prime ad placements. Tailored tiers for bookable and non-bookable businesses. Enhance online presence, drive bookings, and grow with Mermaid Molly's chatbot marketing." />
        <meta name="keywords" content="advertising opportunities, KeySaver, Mermaid Molly, chatbot marketing, SEO-optimized content, social media promotion, bookable businesses, non-bookable businesses, business visibility, digital marketing solutions, partner programs, Key West businesses, advertising tiers, online booking" />
        <meta property="og:title" content="KeySaver Advertising - Elevate Your Brand with Tailored Marketing Solutions"/>
        <meta property="og:description" content="Partner with KeySaver to access a suite of customizable marketing solutions. Tap into the power of Mermaid Molly's chatbot platform for SEO content and strategic promotions that connect you with the Key West community and beyond. Explore tiers for both bookable and non-bookable businesses."/>
        <meta property="og:url" content="https://keysaver.app/chatbot-pricing"/>
        <meta property="og:image" content="https://keysaver.app/static/media/KeySaverLogo1.97ddb4cc3d6faed63fa4.webp"/>
        <link rel="canonical" href="https://keysaver.app/chatbot-pricing"/>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}></script>
      </Helmet>

      <h1 className="text-4xl font-bold mb-8 text-center text-blue-900" data-chatbot-title="AdvertiseTitle">KeySaver Partner & Advertising Opportunities</h1>
      <div className="bg-white p-4 rounded-lg text-center shadow-lg mb-8 w-3/4">
  <h2 className="text-2xl font-semibold mb-4 relative inline-block">Description and Image Content Services
    <span className="block w-3/8 h-0.5 bg-black mt-1"></span>
  </h2>
  <p className="text-lg text-blue-900 mb-4">Elevate Your Online Presence: Unlock the full potential of your online listings with KeySaver's Description and Image Content Services.</p>

  {/* Detailed Service Descriptions */}
  <div className="text-lg text-blue-900 mb-4">
    <h3 className="font-bold mb-2 relative inline-block">What You'll Get:
      <span className="block w-3/8 h-0.5 bg-black mt-1"></span>
    </h3>
    <p className="mb-2"><strong>SEO-Optimized Descriptions:</strong> We craft captivating, keyword-rich descriptions tailored to your unique offerings, designed to climb search engine rankings.</p>
    <p className="mb-2"><strong>High-Quality Images:</strong> Visual appeal is critical in the tourism and hospitality industry. Our service includes up to three striking, AI-enhanced images.</p>
    
    <h3 className="font-bold mb-2 relative inline-block">Service Impact:
      <span className="block w-3/8 h-0.5 bg-black mt-1"></span>
    </h3>
    <ul className="list-disc list-inside">
      <li><strong>Improved Bookability:</strong> A polished and professional online presence directly contributes to higher booking rates.</li>
      <li><strong>Enhanced Customer Perception:</strong> High-quality descriptions and images significantly improve the perceived value of your experience.</li>
      <li><strong>Consistent Brand Representation:</strong> Reflect the essence of your brand and what customers can expect.</li>
    </ul>
  </div>
  
  {/* Pricing Tiers */}
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
    {/* Tier for KeySaver's Partners */}
    <div className="bg-gray-100 p-4 rounded-lg">
      <h3 className="text-xl font-semibold mb-2 relative inline-block">For KeySaver's Partners
        <span className="block w-3/8 h-0.5 bg-black mt-1"></span>
      </h3>
      <p className="text-blue-900 mb-2">Pricing: $125 per experience</p>
      <ul className="list-disc list-inside text-blue-900 mb-4">
        <li>Alignment with KeySaver's platform</li>
        <li>Increased visibility and bookings</li>
        <li>Reduced price as part of value exchange</li>
      </ul>
    </div>

    {/* Tier for External Experience Providers */}
    <div className="bg-gray-100 p-4 rounded-lg">
      <h3 className="text-xl font-semibold mb-2 relative inline-block">For Non-Partners
        <span className="block w-3/8 h-0.5 bg-black mt-1"></span>
      </h3>
      <p className="text-blue-900 mb-2">Pricing: $175 per experience</p>
      <ul className="list-disc list-inside text-blue-900 mb-4">
        <li>Custom SEO approach to target audience</li>
        <li>Captivating visual and narrative representation</li>
        <li>A competitive edge in the digital space</li>
      </ul>
    </div>
  </div>

  {/* CTA Button */}
<Link 
  to="/contact" 
  className="inline-block bg-teal-400 text-white px-4 py-2 rounded hover:bg-teal-600 transition-colors duration-200"
>
  Contact Us
</Link>

{/* New Description Section */}
<div className="bg-white p-6 rounded-lg text-center shadow-lg my-8 mx-4 md:mx-auto md:w-full lg:w-3/4">
  <h2 className="text-2xl font-bold mb-6">
    Unlock Key West's Full Potential with KeySaver
  </h2>
    <p className="text-lg text-gray-700 mb-4">
      Welcome to KeySaver Partner & Advertising Opportunities – your gateway to unparalleled exposure in the vibrant Key West community. KeySaver is not just a platform, it's a destination where tourists and locals alike come to discover the best experiences the Keys have to offer. We're passionate about connecting customers with businesses like yours, and we go beyond the basics to help you shine.
    </p>
    <p className="text-lg text-gray-700 mb-4">
      <strong>For Local Businesses:</strong><br />
      Whether you're a seasoned operator with a fleet of charters or a boutique cafe nestled in the heart of Key West, our advertising opportunities are designed to elevate your presence and drive business directly to your door. Tailored to fit your unique needs, KeySaver offers different tiers of partnership and advertising to best align with your goals.
    </p>
    <p className="text-lg text-gray-700 mb-4">
      <strong>For Experience Providers:</strong><br />
      Our platform specializes in converting curious browsers into eager participants. With a spotlight on your bookable experiences, enhanced through SEO-optimized descriptions and vivid imagery, we ensure your adventures are showcased to captivate an audience ready to engage.
    </p>
    <p className="text-lg text-gray-700 mb-4">
      <strong>Our Partner Tiers Include:</strong><br />
      Silver and Gold tiers for heightened visibility and premium content creation.<br />
      A Community Support tier dedicated to fostering the growth of emerging businesses.
    </p>
    <p className="text-lg text-gray-700 mb-4">
      <strong>Why Partner with KeySaver?</strong><br />
      Targeted Reach: We don't just advertise; we connect. Our platform ensures your message resonates with an audience that's inherently interested in what you offer.<br />
      Community Focus: A listing with KeySaver isn't just about business—it's about becoming a recognized name within a thriving community.<br />
      Editorial Excellence: From crafted articles to social media campaigns, our content resonates, enriches, and engages.<br />
      Strategic Placement: With options ranging from website ad space to features in our popular ‘Mermaid Molly’ chat, we curate your presence to ensure maximum impact.
    </p>
    <p className="text-lg text-gray-700 mb-4">
      <strong>Specialized Content Services:</strong><br />
      For experiences we directly sell, we offer attractive bundles that combine our booking platform's audience reach with our marketing services, increasing your bookings and brand presence. For external experience providers, we offer standalone content services, honing your digital footprint to perfection.
    </p>
    <p className="text-lg text-gray-700 mb-4">
      <strong>Our Promise:</strong><br />
      As KeySaver, we're committed to creating partnerships that pave the way for success and sustainability. This is where culture meets commerce, where your business becomes a part of the everlasting Key West story. Join us, and let’s navigate the tides of business growth together.
    </p>
    <p className="text-lg text-gray-700">
      <strong>Reach Out Today:</strong><br />
      Explore the possibilities with KeySaver. Learn more about our partnership tiers, advertising opportunities, and content services. Contact us to discuss how we can tailor our offerings to your business's specific needs and start writing your success story.
    </p>
  </div>

{/* Bookable Businesses Tiers Section */}
<div className="w-full max-w-screen-xl px-4">
  <h2 className="text-2xl font-bold mb-4 text-center">Bookable Businesses Tiers</h2>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
    {/* Bronze Tier */}
    <div className="bg-white p-4 rounded-lg text-center shadow-lg flex flex-col">
      <div className="bg-teal-600 text-white p-2 rounded-t-lg">
        <h3 className="text-xl font-semibold">Bronze Tier</h3>
      </div>
      <ul className="text-blue-900 flex-grow mb-4 list-disc list-inside">
        <li>Editorial selection based on community impact</li>
        <li>Third placement in Mermaid Molly's applicable recommendations</li>
        <li>Occasional social media features</li>
      </ul>
      <p className="text-blue-900 mb-2">Complimentary</p>
    </div>

    {/* Silver Tier */}
    <div className="bg-white p-4 rounded-lg text-center shadow-lg flex flex-col">
      <div className="bg-teal-600 text-white p-2 rounded-t-lg">
        <h3 className="text-xl font-semibold">Silver Tier</h3>
      </div>
      <ul className="text-blue-900 flex-grow mb-4 list-disc list-inside">
        <li>Second placement in Mermaid Molly's applicable recommendations</li>
        <li>One in-depth SEO rich article per month</li>
        <li>Weekly featured social media posts</li>
        <li>Second placement in website advertising slots</li>
      </ul>
      <p className="text-blue-900 mb-2">Pricing: $300/month</p>
    </div>

    {/* Gold Tier */}
    <div className="bg-white p-4 rounded-lg text-center shadow-lg flex flex-col">
      <div className="bg-teal-600 text-white p-2 rounded-t-lg">
        <h3 className="text-xl font-semibold">Gold Tier</h3>
      </div>
      <ul className="text-blue-900 flex-grow mb-4 list-disc list-inside">
        <li>Top placement in Mermaid Molly's applicable recommendations</li>
        <li>Two in-depth SEO rich articles per month</li>
        <li>Weekly featured social media posts</li>
        <li>Premium placement in website advertising slots</li>
      </ul>
      <p className="text-blue-900 mb-2">Pricing: $500/month</p>
    </div>
  </div>
  <Link to="/contact" className="mb-8 inline-block bg-teal-400 text-white px-4 py-2 rounded hover:bg-teal-600 transition duration-200">Contact Us</Link>
</div>

{/* Non-Bookable Businesses Tiers Section */}
<div className="w-full max-w-screen-xl px-4">
  <h2 className="text-2xl font-bold mb-4 text-center">Non-Bookable Businesses Tiers</h2>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
    {/* Bronze Tier */}
    <div className="bg-white p-4 rounded-lg text-center shadow-lg flex flex-col">
      <div className="bg-teal-600 text-white p-2 rounded-t-lg">
        <h3 className="text-xl font-semibold">Bronze Tier</h3>
      </div>
      <ul className="text-blue-900 flex-grow mb-4 list-disc list-inside">
        <li>Editorial selection based on community impact</li>
        <li>Third placement in Mermaid Molly's applicable recommendations</li>
        <li>Occasional social media features</li>
      </ul>
      <p className="text-blue-900 mb-2">Complimentary</p>
    </div>

    {/* Silver Tier */}
    <div className="bg-white p-4 rounded-lg text-center shadow-lg flex flex-col">
      <div className="bg-teal-600 text-white p-2 rounded-t-lg">
        <h3 className="text-xl font-semibold">Silver Tier</h3>
      </div>
      <ul className="text-blue-900 flex-grow mb-4 list-disc list-inside">
        <li>Second placement in Mermaid Molly's applicable recommendations</li>
        <li>One in-depth SEO rich article per month</li>
        <li>Weekly featured social media posts</li>
        <li>Second placement in website advertising slots</li>
      </ul>
      <p className="text-blue-900 mb-2">Pricing: $500/month</p>
    </div>

    {/* Gold Tier */}
    <div className="bg-white p-4 rounded-lg text-center shadow-lg flex flex-col">
      <div className="bg-teal-600 text-white p-2 rounded-t-lg">
        <h3 className="text-xl font-semibold">Gold Tier</h3>
      </div>
      <ul className="text-blue-900 flex-grow mb-4 list-disc list-inside">
        <li>Top placement in Mermaid Molly's applicable recommendations</li>
        <li>Two in-depth SEO rich articles per month</li>
        <li>Weekly featured social media posts</li>
        <li>Premium placement in website advertising slots</li>
      </ul>
      <p className="text-blue-900 mb-2">Pricing: $750/month</p>
    </div>
  </div>
  <Link to="/contact" className="mb-8 inline-block bg-teal-400 text-white px-4 py-2 rounded hover:bg-teal-600 transition duration-200">Contact Us</Link>
</div>

      {/* Community Support Tier In-Depth Section */}
      <div className="w-full max-w-screen-xl px-4">
        <div className="bg-white p-4 rounded-lg text-center shadow-lg mb-8">
          <h2 className="text-2xl font-semibold mb-4">Community Support Tier - Bronze Tier</h2>
          <p className="text-lg text-blue-900 mb-4">Dedicated to supporting emerging or small businesses with limited advertising budgets, offering editorially determined periodic mentions and social media features.</p>
          <p className="font-bold">Eligibility and selections are editorially determined.</p>
        </div>
      </div> 
      </div>
      </div> // This is the closing tag for the main container `div`
  );
};

export default ChatbotPricing;