import React from "react";
import { Helmet } from 'react-helmet';
import Hydro1 from "../../assets/HydroBikes/Hydro1.png";
import Hydro2 from "../../assets/HydroBikes/Hydro2.png";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const HydroBikes = () => {
  const articles = [
    {
      title: "Key West Hydro Bike Rentals & Day Tours",
      description:
        "Explore Key West waters on hydro bikes with options ranging from $45.95 for a half-day single-seat rental to $175.95 for a full-day double-seat adventure. Guided tours are available at $45.95 per single-seat bike with a minimum group requirement, or for a more intimate experience, an additional $75 if under the guest minimum.",
      img: Hydro1,
      url: "https://fareharbor.com/embeds/book/lagerheadswatersports/items/414432/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Sunset Hydro Bike Tour",
      description:
        "Witness the breathtaking Key West sunset on a hydro bike for $55.95 per single-seat or $105.95 for a double-seat. Special rates for locals, first responders, and military personnel at $29.95. Private charters are available for $475, accommodating up to 16 people for an exclusive 60-minute experience.",
      img: Hydro2,
      url: "https://fareharbor.com/embeds/book/lagerheadswatersports/items/353563/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];  

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>Key West Hydro Bike Adventures - Unique Water Experiences</title>
        <meta name="description" content="Enjoy the scenic beauty of Key West on hydro bikes. Offering a range of rentals and tours, including the exclusive sunset hydro bike tour. Perfect for all ages and skill levels." />
        <meta name="keywords" content="Key West, Hydro Bikes, Water Tours, Sunset Tours, Adventure, Water Sports, Family Friendly, Guided Tours"/>
        <meta property="og:title" content="Hydro Bike Adventures in Key West - Explore the Waters"/>
        <meta property="og:description" content="Experience Key West from the unique perspective of a hydro bike. Join us for day tours or the mesmerizing sunset hydro bike adventure."/>
        <meta property="og:url" content="https://keysaver.app/hydro-bikes"/>
        <meta property="og:image" content="https://keysaver.app/static/media/Hydro_Bike.3e5e984bcb35e5ebfd49.jpg"/>
        <link rel="canonical" href="https://keysaver.app/hydro-bikes"/>
      </Helmet>

      <section className="mb-32 text-gray-800">
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences - Hydro Bikes, Hobie Cats, & Fun Cats
        </h1>
        <div className="grid lg:grid-cols-3 gap-16">
          {articles.map((article, index) => (
            <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
                <a href={article.url} target="_blank">
                  <img
                    src={article.img}
                    className="w-full h-64 object-cover rounded-t-lg"
                    alt={`Hydro Bike Experience: ${article.title} - ${article.description}`} // More descriptive alt text
                  />
                  <div className="text-black p-6">
                    <h5 className="font-bold text-2xl mb-3 font-Poppins">
                      {article.title}
                    </h5>
                    <p className="mb-3">
                      <small>
                        {article.description}
                      </small>
                    </p>
                    <a
                      target="_blank"
                      href={article.url}
                      className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                    >
                      Book Now
                    </a>
                  </div>
                </a>
              </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default HydroBikes;
