import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import AvatarImg from "../assets/Misc/Avatar_Dashboard.jpg";
import { useAuth } from '../auth/AuthContext';
import { checkVerified } from '../lib/checkVerified';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Dashboard = () => {
  const { user } = useAuth();
  const [username, setUsername] = useState("unknown");
  const [isVerified, setIsVerified] = useState(false);
  const [referralLink, setReferralLink] = useState("");

  useEffect(() => {
    if (!user) return;

    setUsername(user.email);
    setReferralLink(`https://keysaver.app/login?ref=${user.id}`);

    checkVerified(user.id)
      .then((verified) => {
        setIsVerified(verified);
      });
  }, [user]);

  return (
    <div className="container my-28 mx-auto md:px-6">
      <Helmet>
        <title>Dashboard - KeySaver</title>
        <meta name="description" content="Access your personalized KeySaver dashboard to manage your digital concierge services and local verification process." />
      </Helmet>

      <section className="mb-32 text-center">
        <h1 className="mb-44 text-3xl font-bold">
          Welcome <u className="text-blue-500 dark:text-blue-400">{username}!</u>
        </h1>

        <div className="grid gap-x-6 md:grid-cols-5 lg:gap-x-12">
          <div className="md:col-start-2 md:col-span-3 mb-24 md:mb-0">
            <div className="block h-full rounded-lg bg-white shadow-lg">
              <div className="flex justify-center">
                <div className="flex justify-center -mt-[75px]">
                  <div className="rounded-full overflow-hidden border-4 border-white">
                    <img
                      src={AvatarImg}
                      className="w-[200px] h-[200px] object-cover"
                      alt="User Avatar"
                      loading="lazy" // Deferred image loading
                    />
                  </div>
                </div>
              </div>
              <div className="p-6">
                <h5 className="mb-4 text-lg font-bold text-blue-500">
                  {username}
                </h5>
                {isVerified ? (
                  <div className="flex flex-col items-center mt-4">
                    <h3 className="text-2xl font-bold mb-4">Verified Local</h3>
                    <QRCode
                      value={referralLink || "default_value"}
                      size={128}
                    />
                  </div>
                ) : (
                  <>
                    <p className="text-lg mb-3 text-black">Interested in becoming a digital concierge for KeySaver?</p>
                    <p className="text-base sm:text-lg mb-3 text-black">Request your local verification and receive a personalized QR code. Simply click the button below, fill out the contact form indicating your interest in local verification, and our team will promptly assist you with the next steps.</p>
                    <Link to="/contact" className="inline-block bg-blue-500 text-white px-4 py-2 rounded mt-4 hover:bg-blue-700 focus:outline-none focus:bg-blue-700">
                      Key West Local Verification
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
