import React from "react";
import { Helmet } from 'react-helmet';
import Ski2 from "../../assets/JetSkis/Ski2.png";
import Ski3 from "../../assets/JetSkis/Ski3.png";
import Ski4 from "../../assets/JetSkis/Ski4.png";

const styles = {
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  box: {
      boxSizing: 'border-box',
      flex: '0 1 calc(33.33% - 10px)',
      marginBottom: '15px',
  },
  boxResponsive: {
      '@media (max-width: 800px)': {
          flex: '0 1 calc(50% - 10px)',
      },
      '@media (max-width: 500px)': {
          flex: '0 1 100%',
      },
  },
};

const JetSkisTours = () => {
  const articles = [
    {
      title: "Casa Marina Island Jet Ski Tours",
      description:
        "90-minute, 27-mile guided jet ski adventure around Key West from Casa Marina Resort. Includes history stops and potential wildlife sightings. Rates: $160 per machine, tax not included. Check-in 30-60 minutes before departure.",
      img: Ski2,
      url: "https://fareharbor.com/embeds/book/barefootbillyskw/items/102691/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Key West Island Jet Ski Tours",
      description:
        "Ibis Bay's 1.5-hour jet ski tour offers a 27-mile scenic route around Key West. Includes guided tour and swim stops. Prices: $160 single, $180 double. Bring ID and credit/debit card.",
      img: Ski3,
      url: "https://fareharbor.com/embeds/book/ibisbaypaddlesports/items/408381/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
    {
      title: "Reach Resort Island Jet Ski Tours",
      description:
        "2-hour high-speed tour from Reach Resort, circling Key West with historical insights and a chance to swim at a sandbar. Single or double riders: $160 plus tax. Photo ID required; age and licensing restrictions apply.",
      img: Ski4,
      url: "https://fareharbor.com/embeds/book/barefootbillyskw/items/102738/calendar/2023/06/?flow=no&asn=fhdn&asn-ref=boozefreeinkeywest&full-items=yes&ref=boozefreeinkeywest&branding=yes",
    },
  ];

  return (
    <div className="container my-24 px-6 mx-auto">
      <Helmet>
        <title>Key West Jet Ski Tours - Thrilling Water Adventures</title>
        <meta name="description" content="Join the excitement with Key West Jet Ski Tours. Experience high-speed jet ski rides around Key West's beautiful waters, with guided tours offering both adventure and historical insights." />
        <meta name="keywords" content="Jet Ski Tours, Key West, Water Sports, High-Speed Adventure, Guided Tours, Scenic Routes, Island Tours"/>
        <meta property="og:title" content="Key West Jet Ski Adventures - High-Speed Water Fun"/>
        <meta property="og:description" content="Discover the thrill of jet skiing in Key West. Explore our scenic routes and historical tours for an unforgettable water adventure."/>
        <meta property="og:url" content="https://keysaver.app/jet-ski-tours"/>
        <meta property="og:image" content="https://keysaver.app/static/media/jetski.d93f550db5891b375395.png"/>
        <link rel="canonical" href="https://keysaver.app/jet-ski-tours"/>
      </Helmet>

      <section className="mb-32 text-gray-800">
        <h1 className="text-3xl font-bold mb-12 text-center">
          Book Experiences - Jet Ski Tours
        </h1>
        <div className="grid lg:grid-cols-3 gap-16">
          {articles.map((article, index) => {
            return (
              <div key={index} className="shadow-lg rounded-lg hover:opacity-75 transition-opacity duration-500 ease-in-out text-center">
                <a href={article.url} target="_blank" rel="noopener noreferrer">
                  <img
                    src={article.img}
                    className="w-full h-64 object-cover rounded-t-lg"
                    alt={`Jet Ski Tour: ${article.title} - ${article.description}`} // More descriptive alt text
                  />
                  <div className="text-black p-6">
                    <h5 className="font-bold text-2xl mb-3 font-Poppins">
                      {article.title}
                    </h5>
                    <p className="mb-3">
                      <small>
                        {article.description}
                      </small>
                    </p>
                    <a
                      target="_blank"
                      href={article.url}
                      className="font-roboto bg-white text-black px-4 py-2 rounded shadow-lg hover:bg-gray-300 transition duration-200"
                      rel="noopener noreferrer"
                    >
                      Book Now
                    </a>
                  </div>
                </a>
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default JetSkisTours;