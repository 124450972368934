import React from "react";
import "./Footer.css";
import { BsFacebook, BsLinkedin } from "react-icons/bs";
import { IoLogoTwitter } from "react-icons/io";

const Footer = () => {
  const handleContactClick = () => {
    // You can add your contact click handling logic here
  };

  return (
    <footer>
      <div className="footer">
        <ul className="permalinks">
          <li>
            <a href="#">Home</a>
          </li>
          <li>
            <a href="/book">Book</a> 
          </li>
          <li>
            <a href="https://keysaver.app/articles">Articles</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
          <a href="/contact">Contact</a>
          </li>
        </ul>
        <div className="footer__socials">
          <a href="https://www.facebook.com/KeySaverKW/" target="_blank">
            <BsFacebook />
          </a>
          <a href="https://www.linkedin.com/company/modmana/" target="_blank">
            <BsLinkedin />
          </a>
          <a href="https://twitter.com/KeySaverKW" target="_blank">
            <IoLogoTwitter />
          </a>
        </div>
        <div className="footer__copyrigh">
          <small>&copy; 2023 KeySaver - All Rights Reserved.</small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;