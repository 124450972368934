import supabase from '../utils/supabase';
import {useEffect, useState} from 'react';
import {AuthContext} from './AuthContext';

export function AuthProvider({children}) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Check for an existing session on initial load
    supabase.auth.getSession().then(({data: {session}}) => {
      if (session) {  // Check if session is not null
        setUser(session.user);
      }
      setLoading(false);
    })

    // Set up a session listener
    const {data: authListener} = supabase.auth.onAuthStateChange(
      (event, session) => {
        setUser(session?.user || null);
        setLoading(false);
      }
    )

    // Cleanup listener on unmount
    return () => {
      authListener?.subscription?.unsubscribe();
    }
  }, [])

  const contextValue = {
    user,
    loading,
    signOut: () => supabase.auth.signOut()
    // Add any other auth related methods you need
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}
