import React from "react";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen pt-20"> {/* Adjusted for proper spacing */}
      <Helmet>
        <title>Contact Us - KeySaver</title>
        <meta name="description" content="Reach out to KeySaver for any inquiries or support. Contact us via email or phone, or fill out our contact form for personalized assistance." />
      </Helmet>

      <div className="text-center mb-8 w-full max-w-md px-4"> {/* Container for contact info */}
        <h1 className="text-3xl font-bold mb-4">Contact KeySaver</h1>
        <p className="mb-4">Have questions or need assistance? We're here to help!</p>
        <p>
          <a href="mailto:info@keysaver.app" className="text-blue-600 hover:text-blue-800">
            Email: info@keysaver.app
          </a>
        </p>
        <p>
          <a href="tel:+13057095548" className="text-blue-600 hover:text-blue-800">
            Phone: 305-709-5548
          </a>
        </p>
      </div>

      <iframe
        title="Contact Us Form"
        className="w-full"
        src="https://us12.list-manage.com/contact-form?u=8e399b07e9c2c43dfef81c5de&form_id=f061353dbac83eefdeade61c6c7b51b3"
        height="800"
        frameBorder="0"
        loading="lazy"
        aria-label="Contact Us Form"
        style={{ maxWidth: '600px' }} // Adjust the width of the iframe as needed
      />
    </div>
  );
};

export default ContactUs;

